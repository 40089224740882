body,
html {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.MuiPagination-root .MuiPagination-ul li:not(:first-child):not(:last-child) {
  display: none;
}

.MuiPagination-root .MuiPagination-ul li:first-child > button {
  background-color: #0c67ef;
  color: white;
}

.MuiPagination-root .MuiPagination-ul li:last-child > button {
  background-color: #0c67ef;
  color: white;
}

.MuiPagination-root .MuiPagination-ul li:first-child > button::after {
  content: 'Previous';
  margin-left: 10px;
}

.MuiPagination-root .MuiPagination-ul li:last-child > button::before {
  content: 'Next';
  margin-right: 10px;
}


